import {
  cancelledTrialPlansStatusData,
  cancelledNotTrialPlansStatusData,
  datePickerDefaultState,
  planStatusDefaultState,
} from './data';

import { PlanStatusEnum } from 'pages/users/entities';
import {
  FormStateSetter,
  PlanDetailsDates,
  PlanDetailsStateSetters,
  UserPlanFormFields,
} from './entities';
import { addDayToDate } from 'utils/helpers';

const ADD_DAY_TO_DATE = 0;

/*
    Plan name = Trial (not changeable)
    PlanStatus = Trialling (not changeable)
    Trial Exp Date changeable with restriction (restriction is that I disabled all previous dates before trial exp. date + 2 day)
*/
const planTrialStatusTrialing = (
  { setPlansName, setPlansStatus, setTrialExpDate }: any,
  userTrialEndDate: any
) => {
  setPlansName({ disabled: true });
  setPlansStatus({ disabled: true });
  setTrialExpDate({ isVisible: true, minDate: addDayToDate(userTrialEndDate, 2) });
};

/*
    Plan Name = Trial (Not changeable)
    Plan Status = Cancelled (changeable only to Trialling and should not be disabled)
    If Plan Status was changed to Trialling Trial Exp Date should be visible with restriction starting from current date + 6 days
*/
const planTrialStatusCancelled = (
  { setPlansName, setPlansStatus, setTrialExpDate }: any,
  setFormValue: any,
  userTrialEndDate: any
) => {
  setPlansName({ disabled: true });
  setPlansStatus({ disabled: false, data: cancelledTrialPlansStatusData });
  setTrialExpDate(datePickerDefaultState);
  setFormValue('plan.trialEndDate', userTrialEndDate);
};

const planTrialPrevStatusCancelledStatusTrial = ({
  setPlansName,
  setPlansStatus,
  setTrialExpDate,
}: any) => {
  const minDate = addDayToDate(new Date(), 6);

  setPlansName({ disabled: true });
  setPlansStatus({ disabled: false, data: cancelledTrialPlansStatusData });
  setTrialExpDate({ isVisible: true, minDate, defaultCalendarMonth: minDate });
};

/*
  Plan Name is not equal Trial (not changeable)
  PlanStatus = Trialling (not changeable)
  Next Charge Date with restriction starting from userNextChargeDate + 2 days
*/
const planNotTrialStatusTrialing = (
  { setPlansName, setPlansStatus, setNextChargeDate }: any,
  userNextChargeDate: any
) => {
  setPlansName({ disabled: true });
  setPlansStatus({ disabled: true });
  setNextChargeDate({
    isVisible: true,
    minDate: addDayToDate(userNextChargeDate, ADD_DAY_TO_DATE),
  });
};

/*
  Plan name doesn't matter here
  PlanStatus = Active (not changeable)
  Next Charge Date with restriction starting from userNextChargeDate + 2 days
*/
const statusActivePrevStatusNull = (
  { setPlansName, setPlansStatus, setNextChargeDate }: any,
  userNextChargeDate: any,
  setFormValue: any
) => {
  setPlansName({ disabled: true });
  setPlansStatus({ disabled: true });
  setNextChargeDate({
    isVisible: true,
    minDate: addDayToDate(userNextChargeDate, ADD_DAY_TO_DATE),
  });
  setFormValue('plan.nextChargeDate', userNextChargeDate);
};

/*
  Plan Name is not equal to Trial (not changeable)
  Plan Status = Cancelled changeable only to Active and should not be disabled
  If Plan Status was changed to Active Next charge should appear with date restriction starting from user next charge date + 2 days
*/
const planNotTrialStatusCancelled = (
  { setPlansName, setPlansStatus, setNextChargeDate }: any,
  setFormValue: any,
  userNextChargeDate: any
) => {
  setPlansName({ disabled: true });
  setPlansStatus({ disabled: false, data: cancelledNotTrialPlansStatusData });
  setNextChargeDate(datePickerDefaultState);
  setFormValue('plan.nextChargeDate', userNextChargeDate);
};

const planNotTrialStatusActivePrevStatusCancelled = (
  { setPlansName, setPlansStatus, setNextChargeDate }: any,
  userNextChargeDate: any,
  setFormValue: any
) => {
  setPlansName({ disabled: true });
  setPlansStatus({ disabled: false, data: cancelledNotTrialPlansStatusData });
  setNextChargeDate({
    isVisible: true,
    minDate: addDayToDate(userNextChargeDate, ADD_DAY_TO_DATE),
  });
  setFormValue('plan.nextChargeDate', userNextChargeDate);
};

/*
  Plan Name is equal to Trial (not changeable)
  Plan Status = Active (not changeable)
  Trial end date 
*/
const planTrialStatusActivePrevStatusNull = (
  { setPlansName, setPlansStatus, setTrialExpDate }: any,
  userTrialEndDate: any
) => {
  setPlansName({ disabled: true });
  setPlansStatus({ disabled: true });
  setTrialExpDate({ isVisible: true, disablePast: false, minDate: userTrialEndDate });
};

/************************************************************
 * CONFIGURATIONS START
 ********************************************************** */
export const configurePaymentDetailsForm = (
  userPlanFormFields: UserPlanFormFields,
  planDetailsDates: PlanDetailsDates,
  formStateSetter: FormStateSetter,
  setters: PlanDetailsStateSetters
) => {
  const setPlansName = (props: any) => {
    setters.setPlansNameProps((prev: any) => ({ ...prev, disabled: true, ...props }));
  };

  const setPlansStatus = (props: any) => {
    setters.setPlansStatusProps({ ...planStatusDefaultState, ...props });
  };

  const setTrialExpDate = (rest: any) => {
    setters.setDatePickerProps({
      ...datePickerDefaultState,
      key: 'trialEndDate',
      name: 'plan.trialEndDate',
      label: 'Trial Expiration Date',
      ...rest,
    });
  };

  const setNextChargeDate = (rest: any) => {
    setters.setDatePickerProps({
      ...datePickerDefaultState,
      key: 'nextChargeDate',
      name: 'plan.nextChargeDate',
      label: 'Next Charge Date',
      ...rest,
    });
  };

  const paymentDetailsFormSetters = {
    setPlansName,
    setPlansStatus,
    setTrialExpDate,
    setNextChargeDate,
  };

  const { planName, prevPlanStatus, planStatus } = userPlanFormFields;
  const { userTrialEndDate, userNextChargeDate } = planDetailsDates;
  const { setFormValue } = formStateSetter;

  /************************************************************
   * CONDITIONS START
   ********************************************************** */
  if (planName === 'Trial' && planStatus === PlanStatusEnum.TRIALING && prevPlanStatus === null) {
    return planTrialStatusTrialing(paymentDetailsFormSetters, userTrialEndDate);
  }

  if (planName === 'Trial' && planStatus === PlanStatusEnum.INACTIVE) {
    return planTrialStatusCancelled(paymentDetailsFormSetters, setFormValue, userTrialEndDate);
  }

  if (
    planName === 'Trial' &&
    planStatus === PlanStatusEnum.TRIALING &&
    prevPlanStatus === PlanStatusEnum.INACTIVE
  ) {
    return planTrialPrevStatusCancelledStatusTrial(paymentDetailsFormSetters);
  }

  if (planName === 'Trial' && planStatus === PlanStatusEnum.ACTIVE && prevPlanStatus === null) {
    return planTrialStatusActivePrevStatusNull(paymentDetailsFormSetters, userTrialEndDate);
  }

  if (planName !== 'Trial' && planStatus === PlanStatusEnum.TRIALING) {
    return planNotTrialStatusTrialing(paymentDetailsFormSetters, userNextChargeDate);
  }

  if (planName !== 'Trial' && planStatus === PlanStatusEnum.ACTIVE && prevPlanStatus === null) {
    return statusActivePrevStatusNull(paymentDetailsFormSetters, userNextChargeDate, setFormValue);
  }

  if (planName !== 'Trial' && planStatus === PlanStatusEnum.INACTIVE) {
    return planNotTrialStatusCancelled(paymentDetailsFormSetters, setFormValue, userNextChargeDate);
  }

  if (
    planName !== 'Trial' &&
    planStatus === PlanStatusEnum.ACTIVE &&
    prevPlanStatus === PlanStatusEnum.INACTIVE
  ) {
    return planNotTrialStatusActivePrevStatusCancelled(
      paymentDetailsFormSetters,
      userNextChargeDate,
      setFormValue
    );
  }

  /************************************************************
   * CONDITIONS END
   ********************************************************** */
};
/************************************************************
 * CONFIGURATIONS END
 ********************************************************** */
